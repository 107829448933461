<div>
  <p>
    One access token is valid for only one hour. When clicked the Login button, I will try to authenticate you again with Spotify to get a new
    access token 😆
  </p>
</div>
<div *nzModalFooter>
  <button nz-button nzType="primary" nzSize="large" nzShape="round" (click)="authenticate()">
    Login
  </button>
</div>
